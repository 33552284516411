import { Injectable } from "@angular/core";
import { RouteInfo } from "app/shared/vertical-menu/vertical-menu.metadata";

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    getMenuRouteInfo(activeClient, activeEvent): RouteInfo[] {
        const clientId = activeClient?.id;
        const eventId = activeEvent?.id;
        const routeInfo: RouteInfo[] = [
            {
                path: '/c/' + clientId + '/dashboard', title: 'Dashboard',
                icon: 'ft-monitor', class: 'dropdown nav-item', isExternalLink: false, submenu: []
            },
        ];

        if (this.moderatorAccess(activeClient) || this.moderatorAccess(activeEvent)) {
            const administration = {
                path: '', title: 'Administration', icon: 'ft-sliders',
                class: 'dropdown nav-item has-sub', isExternalLink: false, submenu: []
            };
            if (this.adminAccess(activeClient)) {
                administration.submenu.push({
                    path: '/clients/' + clientId, title: 'EditClient',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.moderatorAccess(activeClient)) {
                administration.submenu.push({
                    path: '/c/' + clientId + '/users', title: 'Users',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.moderatorAccess(activeEvent) && activeEvent != null) {
                administration.submenu.push({
                    path: '/c/' + clientId + '/e/' + eventId + '/users', title: 'EventUsers',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.superAdminAccess(activeClient)) {
                administration.submenu.push({
                    path: '/apps', title: 'Apps',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.superAdminAccess(activeClient)) {
                administration.submenu.push({
                    path: '/web-apps', title: 'WebApps',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.superAdminAccess(activeClient)) {
                administration.submenu.push({
                    path: '/c/' + clientId + '/e/' + eventId + '/price-templates', title: 'PriceTemplates',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.moderatorAccess(activeEvent) && activeEvent != null) {
                administration.submenu.push({
                    path: '/c/' + clientId + '/e/' + eventId + '/forms', title: 'Forms',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }


            routeInfo.push(administration);
        }

        if (this.moderatorAccess(activeClient) || this.moderatorAccess(activeEvent)) {
            const common = {
                path: '', title: 'Common', icon: 'ft-database', class: 'dropdown nav-item has-sub', isExternalLink: false, submenu: []
            }
            common.submenu.push({
                path: '/c/' + clientId + '/venues', title: 'Venues',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: []
            });
            common.submenu.push({
                path: '/c/' + clientId + '/sponsors', title: 'Sponsors',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: []
            });
            common.submenu.push({
                path: '/c/' + clientId + '/sponsorship-levels', title: 'SponsorshipLevels',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: []
            });
            routeInfo.push(common);
        }
        if (activeEvent == null) { return routeInfo; }

        if (this.moderatorAccess(activeClient) || this.moderatorAccess(activeEvent)) {
            routeInfo.push({
                path: '/c/' + clientId + '/events/' + eventId, title: 'Edit Event',
                icon: 'ft-bar-chart-2', class: 'dropdown nav-item', isExternalLink: false, submenu: []
            });
        }

        if (this.moderatorAccess(activeClient) || this.moderatorAccess(activeEvent)) {
            const promo = {
                path: '', title: 'Promo', icon: 'ft-film', class: 'dropdown nav-item has-sub', isExternalLink: false, submenu: []
            };
            if (this.superAdminAccess(activeClient)) {
                promo.submenu.push({
                    path: '/c/' + clientId + '/events/' + eventId + '/app', title: 'EventApp',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            promo.submenu.push({
                path: '/c/' + clientId + '/e/' + eventId + '/about', title: 'About',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                badge: '', badgeClass: '', isExternalLink: false, submenu: []
            });
            promo.submenu.push({
                path: '/c/' + clientId + '/e/' + eventId + '/news', title: 'News',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                badge: '', badgeClass: '', isExternalLink: false, submenu: []
            });
            promo.submenu.push({
                path: '/c/' + clientId + '/e/' + eventId + '/sponsors', title: 'Sponsors',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                badge: '', badgeClass: '', isExternalLink: false, submenu: []
            });
            routeInfo.push(promo);
        }
        if (this.moderatorAccess(activeClient) || this.speakerOrExhibitorAccess(activeEvent)) {
            const content = {
                path: '', title: 'Content', icon: 'ft-layers', class: 'dropdown nav-item has-sub', isExternalLink: false, submenu: []
            };
            if (this.moderatorAccess(activeClient) || this.speakerAccess(activeEvent)) {
                content.submenu.push({
                    path: '/c/' + clientId + '/e/' + eventId + '/agenda', title: 'Agenda',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.moderatorAccess(activeClient) || this.speakerOrExhibitorAccess(activeEvent)) {
                content.submenu.push({
                    path: '/c/' + clientId + '/e/' + eventId + '/meeting-configs', title: 'MeetingBlocs',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
                content.submenu.push({
                    path: '/c/' + clientId + '/e/' + eventId + '/meetings', title: 'Meetings',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.moderatorAccess(activeClient) || this.speakerAccess(activeEvent)) {
                content.submenu.push({
                    path: '/c/' + clientId + '/e/' + eventId + '/speakers', title: 'Speakers',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            if (this.moderatorAccess(activeClient) || this.exhibitorAccess(activeEvent)) {
                content.submenu.push({
                    path: '/c/' + clientId + '/e/' + eventId + '/exhibitors', title: 'Exhibitors',
                    icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                    badge: '', badgeClass: '', isExternalLink: false, submenu: []
                });
            }
            routeInfo.push(content);
        }
        if (this.importerAccess(activeClient) || this.importerAccess(activeEvent)) {
            routeInfo.push({
                path: '/c/' + clientId + '/e/' + eventId + '/visitors', title: 'Visitors',
                icon: 'ft-heart', class: 'dropdown nav-item', isExternalLink: false, submenu: []
            });
        }
        if (this.adminAccess(activeClient) || this.adminAccess(activeEvent)) {
            const promo = {
                path: '', title: 'AccessControl', icon: 'ft-log-in', class: 'dropdown nav-item has-sub', isExternalLink: false, submenu: []
            };
            promo.submenu.push({
                path: '/c/' + clientId + '/e/' + eventId + '/zone-access-control', title: 'Zone',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                badge: '', badgeClass: '', isExternalLink: false, submenu: []
            });
            promo.submenu.push({
                path: '/c/' + clientId + '/e/' + eventId + '/registrations', title: 'Registrations',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                badge: '', badgeClass: '', isExternalLink: false, submenu: []
            });
            promo.submenu.push({
                path: '/c/' + clientId + '/e/' + eventId + '/unauthorized', title: 'Unauthorized',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                badge: '', badgeClass: '', isExternalLink: false, submenu: []
            });
            promo.submenu.push({
                path: '/c/' + clientId + '/e/' + eventId + '/devices', title: 'Devices',
                icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item',
                badge: '', badgeClass: '', isExternalLink: false, submenu: []
            });
            routeInfo.push(promo);
        }

        return routeInfo;
    }

    superAdminAccess(item) {
        return item?.superAdmin;
    }

    adminAccess(item) {
        return item?.admin || item?.superAdmin;
    }

    moderatorAccess(item) {
        return item?.admin || item?.moderator || item?.superAdmin;
    }

    importerAccess(item) {
        return item?.admin || item?.importer || item?.superAdmin || item?.moderator;
    }

    speakerAccess(item) {
        return item.admin || item.superAdmin || item.moderator || item.speaker;
    }

    exhibitorAccess(item) {
        return item.admin || item.superAdmin || item.moderator || item.exhibitor;
    }

    speakerOrExhibitorAccess(item) {
        return item.admin || item.superAdmin || item.moderator || item.speaker || item.exhibitor;
    }

    anyAccess(item) {
        return item.admin || item.importer || item.moderator || item.superAdmin || item.speaker || item.exhibitor;
    }

}

